.chocolate {
  color: #413c3d;
}
.black {
  color: #302d2d;
}
.karma {
  color: #f56085;
}
.gray {
  color: #9f989a;
}
.light-gray-bg {
  background-color: #f2eeee;
}
.light-mauve {
  color: #c1babc;
}
.orange {
  color: #ff8400;
}
.green-bg {
  background-color: #22c54c;
}
