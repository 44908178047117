body,
input,
textarea,
select {
  font-family: 'Inter', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.body {
  font-size: 17px;
}
.h1 {
  margin: 0 0 15px;
  font-size: 30px;
  letter-spacing: -1px;
  line-height: 1;
}
.h6 {
  font-size: 12px;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.no-underline {
  text-decoration: none;
}
.underline {
  text-decoration: underline;
}
.italic {
  font-style: italic;
}
.pointer {
  cursor: pointer;
}
.form-error input + span,
.form-error select + span {
  display: block;
  text-align: right;
  font-size: 11px;
  color: #9f989a;
  padding-top: 4px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .h1 {
    font-size: 40px;
  }
  .h6 {
    font-size: 17px;
  }
}
