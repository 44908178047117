html {
  height: 100%;
}
/*
 * set scroll padding when showing top navbar
 * to get right scroll position of anchor links
 */
@media only screen and (min-width: 756px) {
  html {
    scroll-padding-top: 75px;
  }
}

body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto 50px;
  text-align: center;
}
.logo {
  width: 60px;
  height: 60px;
  margin: 50px auto 50px;
}

.pointer-events-none {
  pointer-events: none;
}

.button-as-link {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

.highlighted-tutorial-item {
  border: 2px solid #2aba80 !important;
  border-radius: 5px !important;
  padding: 5px;
  /* To avoid border taking up space */
  margin: -2px;
}

::selection {
  background: #c3e6d7; /* WebKit/Blink Browsers */
}

.action {
  color: #019d5f;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
