.DateRangePickerInput {
  border: none;
  border-radius: 8px;
  background-color: transparent;
}
.DateRangePickerInput__disabled {
  background: white;
}
.DateRangePickerInput:focus {
  box-shadow: 0 0 0 2px #302d2d, 0 2px 4px rgba(0, 0, 0, 0.16);
}

.DateRangePicker_picker__fullScreenPortal {
  z-index: 9999;
}

.DayPicker_portal__vertical {
  width: 100%;
}

.DateInput {
  border-radius: 8px;
}

.DateInput_input {
  font-weight: normal;
  font-size: 15px;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16);
}

.DateInput_input__focused {
  border: none;
  box-shadow: 0 0 0 2px #302d2d, 0 2px 4px rgba(0, 0, 0, 0.16);
}

.DateRangePickerInput_arrow {
  margin: 0 8px;
}

.CalendarDay__selected {
  background: #006555;
  border-color: #006555;
  color: white;
}

.CalendarDay__selected:hover {
  background: #29ba80;
  border-color: #29ba80;
  color: white;
}
.CalendarDay__selected_span {
  background: #29ba80;
  border-color: #29ba80;
  color: white;
}
.CalendarDay__selected_span:hover {
  background: #b4d3c7;
  border-color: #b4d3c7;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #29ba80;
  border-color: #29ba80;
  color: white;
}

.DayPickerNavigation_button {
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16);
}

.DayPickerNavigation_button__disabled:hover,
.DayPickerNavigation_button__disabled:hover {
  border: none;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: none;
  box-shadow: 0 0 0 2px #302d2d, 0 2px 4px rgba(0, 0, 0, 0.16);
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: #006555;
}

.SingleDatePickerInput__withBorder {
  background: transparent;
  font-weight: normal;
  font-size: 15px;
  border-radius: 8px;
  text-align: center;
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.16);
}
